import React from 'react';
import BlogLayout from '../components/BlogLayout';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MDXRenderer } from "gatsby-plugin-mdx";



export default function BlogDetails({ data }) {

    const { body } = data.mdx;
    const { title, date, intro, bannerImg } = data.mdx.frontmatter;
    
    const banner = getImage(bannerImg);
    return (
        <BlogLayout>         
            <section className="w-full text-white" id="">
                <div className=" pt-[75px] container text-white text-center text-3xl"> 
                    <h2>{title}</h2>
                    <p className="text-muted">{date}</p>
                </div>
                <GatsbyImage 
                    image={banner} 
                    alt=""
                    formats={["auto", "webp", "avif"]} 
                    />
                <div className="bg-gray-500 w-full px-4 text-center text-white p-4 text-2xl">
                    {intro}
                    {/* <StaticImage 
                        src="../images/summit_logo_transparent.png"
                        width={100}
                        height={70}
                        /> */}
                </div>
                <div className="p-8 " id="blog-details-html">   
                    <MDXRenderer>{body}</MDXRenderer>                
                </div>
            </section>
        </BlogLayout>
    )
}

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date
        description
        title
        intro
        bannerImg {
            childImageSharp {
                gatsbyImageData
            }
        }
      }
    }
  }`

