import React from "react";
import PropTypes from "prop-types";
import { Link, useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { MDXProvider } from "@mdx-js/react"
import "../styles/global.css";
import Seo from "./seo";

// pass bootstrap react components as shortCodes to all 
// blog posts rendered thru MDX provider

const shortCodes= {};

const BlogLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query BlogTitleQuery {
      mdx {
          frontmatter {
              title
              slug
              description
              intro
              thumb {
                publicURL
              }
              bannerImg {
                childImageSharp {
                  gatsbyImageData
                }
              }
              type
            
          }
      }
    }
  `)
   
  const {title, description, thumb, slug, type} = data.mdx.frontmatter

   return (
    <div className="bg-gray-900" id="blogLayout">
      <Seo
          title={title}
          description={description }
          image={thumb.publicURL}
          pathname={slug}
          type={type || 'article'}
      />

      <nav className="p-4 fixed bg-gray-900 w-full" fixed="top" id="blogNav">
          
        <Link to="/" className="">
          <div className="flex items-center text-xl" >
              <StaticImage 
               src="../images/summit_logo_transparent.png"
               alt='Summit Logo'
               width={75}
               height={50}
               className=""
               formats={["auto", "webp", "avif"]} 
              /> {' '}
              <span className="px-4">
                  Back to home page
              </span>
          </div>         
        </Link>    
              
      </nav>
        
      <div className="">
        <MDXProvider components={shortCodes}>{children}</MDXProvider>
      </div>
       
      <footer className="text-center text-white-50 my-3">
         Summit Exteriors, LLC © 2021, Built by {` `} 
         <a href="https://www.amesdean.dev">AmesDean.dev</a> 
         {` `} with {` `}
         <a href="https://www.gatsbyjs.com">Gatsby.js</a>
      </footer>
    </div>
  )
}
 
 BlogLayout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default BlogLayout
 